import React from 'react';
import PageHeader from './basePageComponents/PageHeader';
import radio_selected from './basePageComponents/images/radio_btn_selected.svg';
import radio_not_selected from './basePageComponents/images/radio_btn_not_selected.svg';


export const langList = {
    en: "English",
    iw: "עברית",
    ru: "Русский",
    de: "Deutsch"
};

export default function LanguageSelector({ close = () => null }) {
    const currentLocale = localStorage.getItem("locale");
    return <div className='LangSelectDialog full_window base_background no_select'>
        <PageHeader onBackButtonClick={close} pageTitle="Select language" />
        <div style={{width: 'calc(100% - 50px)', padding: 0, margin: 'auto'}}>
        <ul style={{listStyleType: 'none', padding: 0, margin: 0, userSelect: 'none'}}>
            {Object.keys(langList).map((v, i) => <li
                key={i}
                style={{ margin: 20, padding: 10, borderRadius: 20, backgroundColor: 'white', width: 'calc(100% - 50px)' }}
                onClick={() => {
                    localStorage.setItem("locale", v);
                    window.location.reload();
                }}>

                <div style={{width: 'calc(100% - 25px)', display: 'inline-block'}}>{langList[v]}</div>

                <div style={{display: 'inline-block'}}><img height={20} src={v == currentLocale ? radio_selected : radio_not_selected} alt='selected'/></div>
            </li>)}
        </ul>
        </div>

    </div>
}
/*
const LanguageSelector = ({ locale, setLocale }) => {
    // to do: must be dropdown
    function selected(txt) {
        return (
            <div
                style={{
                    color: '#9598A3',
                    fontSize: '12px',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 700,
                    wordWrap: 'break-word'
                }}
            >
                {txt}
            </div>
        )
    }
    function notSelected(txt, loc) {
        return (
            <div
                onClick={()=> {
                    setLocale(loc);
                    localStorage.setItem("locale", loc);
                }}
                style={{
                    color: '#313E44',
                    fontSize: '12px',
                    textDecoration: 'underline',
                    fontFamily: 'Space Grotesk',
                    fontWeight: 500,
                    wordWrap: 'break-word',
                    cursor: 'pointer'
                }}
            >
                {txt}
            </div>
        );
    }
    return (
        <div
            style={{
                
                width: '40px',
                
                background: '#FFF',
                height: '40px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '4px',
                paddingBottom: '4px',
                borderRadius: '8px',
                border: '2px solid #E0E9FD',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                userSelect: 'none',
                msUserSelect: 'none',
                MozUserSelect: 'none',
                display: 'inline-flex'
            }}
        >
            <table><tbody>

            <tr><td>
            {
                locale == "en" ?
                    selected("English") : notSelected("English", "en")
            }
            </td></tr> 

            <tr><td>
            {
                locale == "iw" ?
                selected("עִבְֿרִית‎") : notSelected("עִבְֿרִית‎", "iw")
            }
            </td></tr>   

            </tbody></table>
            
            
            
        </div>
    );
};


*/