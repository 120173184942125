import ReactModal from "react-modal";
import './ProfileModal.css';
//import close_icon from "./images/close_30.png";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ButtonSecondary from "./buttons/ButtonSecondary";
import user_default from './images/user_default.png';
import t from './translations/translate.ts';
//import ButtonPrimary from "./buttons/ButtonPrimary";
import WhiteDropdown from './buttons/WhiteDropdown.js';

import help_icon from './images/help_button_icon.svg';
import logout_icon from './images/logout_button_icon.svg';
import language_icon from './images/language_icon.svg';
import CONSTS from "./Constants.js";
import { TranslationContextProvider } from "schummar-translate/react";
import { useEffect, useState } from "react";
import isDemoEnabled from "./demo_mode/is_demo_enabled.js";
import DemoAccount from "./demo_mode/demo_account.js";
import Loader from "./Loader.js";
import axios from "axios";
import LanguageSelector, { langList } from "./LanguageSelector.js";
import CircleImage from "./basePageComponents/images/CircleImage.js";
import PageHeader from "./basePageComponents/PageHeader.js";

function setLocale(locale) {
    localStorage.setItem("locale", locale);
    window.location.reload();
}

async function loadData(userInfo, setUserInfo) {
    if (isDemoEnabled()) {
        const demo = new DemoAccount();
        const demoData = {
            "responseFamily": demo.family,
            "responseProfile": demo.supporter,
        };
        setUserInfo(demoData);
    } else {
        const userToken = localStorage.getItem("login_userToken");
        const familyToken = localStorage.getItem("login_familyToken");
        const responseFamily = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`);
        const responseProfile = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}`);
        const data = {
            "responseFamily": responseFamily.data,
            "responseProfile": responseProfile.data,
        };
        setUserInfo(data);
    }
}

export default function ProfileModal({ }) {

    const [userInfo, setUserInfo] = useState(null);
    const [isLangOpen, setLangOpen] = useState(false);
    //useState();
    useEffect(() => {
        if (userInfo == null) {
            setUserInfo({});
            loadData(userInfo, setUserInfo);
        }
    }, [userInfo]);


    var loginHistoryStorage = JSON.parse(localStorage.getItem("loginHistoryStorage") || "[]");

    function generateSubscriptionLink() {
        var family = localStorage.getItem("login_familyToken") || "_demo_";
        var user = localStorage.getItem("login_userToken") || "_demo_";
        var link = 'https://ludenlabs.com/enola_apple_calendar_subscription/';
        link += `${family}/${user}/taken/subscrition.ics`;
        return link;
    }
    const isApple = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i));

    if (isLangOpen) {
        return <LanguageSelector close={() => setLangOpen(false)} />
    }

    if (userInfo == null || Object.keys(userInfo).length === 0) {
        return <Loader />;
    }

    return <TranslationContextProvider locale={localStorage.getItem('locale')}>


        <div className="base_background" style={{
            height: '100vh'
        }}
        >
            <PageHeader onBackButtonClick={()=> window.history.back()} pageTitle={t('menu_profile')}/>
            
            <div style={{ textAlign: 'center', padding: 15, paddingTop: 0, marginBottom: 20, backgroundColor: 'white' }}>
                <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <span className="_black_20_bold">{userInfo.responseProfile.full_name}</span></div>
                <div className="_grey_16" style={{ paddingTop: 4, whiteSpace: 'nowrap', direction: 'ltr', color: '#6B6E70' }}>
                    {userInfo.responseProfile.formatted_phone_number}
                </div>
            </div>
            <div style={{ backgroundColor: 'white', padding: 15, paddingTop: 20, width: 272, margin: 'auto', borderRadius: 25 }}>
                <a href="/my_tasks" className="green_txt"> &nbsp; &nbsp; 
                    {t('profile_allMy')}
                    &nbsp;
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.9668 2.72L10.3135 7.06667C10.8268 7.58 10.8268 8.42 10.3135 8.93333L5.9668 13.28" stroke="#057642" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </a>
                <div >
                    <div style={{ display: 'inline-block', padding: 10, width: 100 }}>
                        <b style={{ fontSize: 30 }}>{userInfo.responseFamily.tasks_counter.my_tasks}</b>       <br />
                        <span style={{ fontSize: 14 }}>{t('profile_takenTasks')}</span>
                    </div>
                    <div style={{ display: 'inline-block', padding: 0, height: 50, marginTop: 10, width: 2, backgroundColor: 'grey' }} />
                    <div style={{ display: 'inline-block', padding: 10, width: 120 }}>
                        <b style={{ fontSize: 30 }}>
                        {userInfo.responseFamily.tasks_counter.completed}    
                        </b>       <br />
                        <span style={{ fontSize: 14 }}>{t('profile_CompletedTasks')}</span>
                    </div>
                </div>
            </div>

            <br />

            <div className="no_select" style={{ backgroundColor: 'white', padding: 15, width: 272, margin: 'auto', borderRadius: 25 }}>
                <div onClick={() => setLangOpen(true)}>
                    <div style={{ display: 'inline-block', width: 'calc(100% - 150px)', paddingLeft: 20, paddingRight: 20, }}>
                        <span className="_grey_16">{t('language')}</span>
                    </div>

                    <div style={{ display: 'inline-block', width: 110, textAlign: 'right', paddingLeft: 0, paddingRight: 0 }}>
                        <span className="_black_bold">{(langList[localStorage.locale])}</span>
                        &nbsp;&nbsp;
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.9668 2.72L10.3135 7.06667C10.8268 7.58 10.8268 8.42 10.3135 8.93333L5.9668 13.28" stroke="#057642" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                </div>
            </div>

            <br />

            <div className="green_txt" style={{
                backgroundColor: 'white', padding: 10, paddingLeft: 20, paddingRight: 20,
                width: 271, margin: 'auto', borderRadius: 25,
                verticalAlign: 'center', cursor: 'pointer'
            }}
                onClick={() => {
                    localStorage.removeItem("login_familyToken");
                    localStorage.removeItem("demoAccount");
                    window.location.reload();
                }}
            >
                <img width={24} height={24} align="top" src={logout_icon} />
                <div style={{ height: 24, display: 'inline-block', paddingTop: 3, verticalAlign: 'middle' }}>
                    &nbsp;
                    {t('profile_logout')}
                </div>
            </div>




            <div style={{ display: 'none' }}>
                &nbsp; &nbsp; {t('switchAccounts')} &nbsp; &nbsp;
            </div>

            <div className="familyList" style={{ display: 'none' }}>

                {loginHistoryStorage.map((v, i) => <div className="familyListItem">
                    <b style={{ paddingRight: 10, paddingLeft: 10 }}>{v.familyName}</b>

                    {!(v.login_familyToken == localStorage.getItem("login_familyToken")) ?
                        <div style={{ width: 90, height: 40, float: 'right' }}><ButtonSecondary onClick={() => {
                            localStorage.setItem("login_familyToken", v.login_familyToken);
                            localStorage.setItem("login_userToken", v.login_userToken);
                            localStorage.removeItem("demoAccount");

                            window.location.reload();
                        }}>{t('switch')}</ButtonSecondary></div> :
                        <>
                            <div style={{ float: 'right' }}> <i>{t('current')}</i> </div>
                        </>
                    }
                </div>)}
            </div>

            {
                (false && isApple) ? <>
                    <div className="ctr">
                        Apple calendar subscription
                    </div>

                    <div style={{ padding: 10, display: 'none' }}>
                        <input id="appleCalendarPromtDisable" type="checkbox" />
                        <label htmlFor="appleCalendarPromtDisable">
                            Don't show "add  to calendar" on task with date taken
                        </label>
                        <br />
                    </div>

                    <table className="ProfileMenuTable">
                        <tbody>

                            <tr>
                                <td colspan="2" onClick={() => {
                                    navigator.clipboard.writeText(generateSubscriptionLink());
                                    NotificationManager.warning("Subscription link copied. Please paste it to Apple Calendar (View tutorial via \"How to use\" button)", "Clipboard", 4200);
                                }}>
                                    Copy subscription link
                                </td>
                            </tr>

                            <tr>
                                <a id="how_to_use_link" style={{ display: 'none' }} href="/helper/ios_calendar_subscription"></a>
                                <td onClick={() => { document.getElementById('how_to_use_link').click(); }}>How to use</td>
                                <td onClick={() => NotificationManager.info(<>
                                    1. No need to add task with date to calendar manually <br /> <br />
                                    2. Can be viewed taken tasks from different families in 1 place
                                </>)}>Advantages</td>
                            </tr>
                        </tbody>
                    </table>

                </> : <></>}


            <br />

            <button
                onClick={() => {
                    NotificationManager.info("Help page is not created yet :(");
                }}
                style={{
                    display: 'none',
                    textAlign: 'left',
                    width: '50%',
                    margin: '0',
                    marginTop: '8px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingRight: '15px',
                    paddingLeft: '15px',
                    verticalAlign: 'center',
                    fontSize: '17px',
                    fontWeight: '600',
                    lineHeight: '18px',
                    backgroundColor: '#fff',
                    borderColor: '#fff',
                    border: '0',
                    cursor: 'pointer',
                    boxShadow: 'none'
                }}>
                <img src={help_icon} />
                &nbsp;&nbsp;
                <span style={{ top: -5, position: 'relative' }}>{t('help')}</span>
            </button>

            <br />

            <button
                onClick={() => {
                    localStorage.removeItem("login_familyToken");
                    localStorage.removeItem("demoAccount");
                    window.location.reload();
                }}
                style={{
                    display: 'none',
                    textAlign: 'left',
                    width: '50%',
                    margin: '0',
                    marginTop: '8px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingRight: '15px',
                    paddingLeft: '15px',
                    verticalAlign: 'center',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '18px',
                    backgroundColor: '#fff',
                    borderColor: '#fff',
                    border: '0',
                    cursor: 'pointer',
                    boxShadow: 'none'
                }}>
                <img src={logout_icon} />
                &nbsp;&nbsp;
                <span style={{ top: -5, position: 'relative' }}>{t('logout')}</span>
            </button>

            <NotificationContainer />
        </div></TranslationContextProvider>;
}

/*

<div style={{ float: 'right', height: 40 }}>
            <button onClick={() => close()} style={{
                margin: 10,
                borderRadius: 10, width: 36,
                border: 'none', cursor: 'pointer',
                height: 36, padding: 0,
            }}>
                <span style={{ fontSize: 32, lineHeight: 0.9 }}>&times;</span>
            </button>
        </div>

<div className="familyListItem">🖼 <b>Alexa's family</b>   <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>
            <div className="familyListItem">🖼 <b>John Do's family</b> <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>
            <div className="familyListItem">🖼 <b>Eve Li's family</b>  <div style={{width: 90, float: 'right'}}><ButtonSecondary>Switch</ButtonSecondary></div></div>

<div className="familyListItem familyListItemCurrent">
            🖼 <b>John's family</b> <i>(selected)</i> <br/>
            Me: Firstname Lastname <br/>
            Coordinator: Coordinator's name, +1234568798 <br/>
            </div>

 <div style={{ display: 'none', width: 60, paddingLeft: 20, paddingRight: 20, }}>
                        <WhiteDropdown
                            txt={(langList[localStorage.locale])}
                            options={[
                                { label: 'He', value: 'iw' },
                                { label: 'En', value: 'en' },
                                { label: 'De', value: 'de' },
                                { label: 'Ru', value: 'ru' },
                            ]}
                            onSelect={(value) => {
                                localStorage.setItem("locale", value);
                                setLocale(value);
                            }}
                        />
                    </div>       
                    
<table style={{ display: 'none' }} className="">
                <tbody>

                    <tr>
                        <td onClick={() => {
                            NotificationManager.info("Help page is not created yet :(")
                        }}>
                            {t('help')}
                        </td>
                        <td onClick={() => {
                            localStorage.removeItem("login_familyToken");
                            localStorage.removeItem("demoAccount");
                            window.location.reload();
                        }}>{t('logout')}</td>
                    </tr>
                </tbody>
            </table>
                    
*/