/*

MY TASKS [HEADER]

Open Tasks list

Done tasks list

*/

import { useEffect, useState } from "react";
import { TranslationContextProvider } from "schummar-translate/react";
import t from '../translations/translate.ts';
import PageHeader from "../basePageComponents/PageHeader.js";
import CONSTS from "../Constants.js";
import { convertAndGroupByDate } from "../Home.js";
import Loader from "../Loader.js";
import TaskCard from "./TaskCard.js";


export default function AllMyTasks({ }) {
    const [wasLoadStarted, setLoadStrted] = useState(false);
    const [tasksData, setTaskData] = useState(null);
    const [tasksData_open, setTaskData_open] = useState(null);
    const [tasksData_closed, setTaskData_closed] = useState(null);
    //
    const profileUrl = `${CONSTS.BASE_API_URL}/supporters/${localStorage.getItem("login_userToken")}`;
    const familyUrl = `${profileUrl}/families/${localStorage.getItem("login_familyToken")}`;
    //
    const loadTasksUrl_no_time = `${profileUrl}/tasks?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=completed`;
    const loadTasksUrl_events = `${profileUrl}/my_events?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=completed`;
    //
    const inProgress_loadTasksUrl_no_time = `${profileUrl}/tasks?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=in_progress`;
    const inProgress_loadTasksUrl_events = `${profileUrl}/my_events?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=in_progress`;
    // in_progress
    //
    async function loadData() {
        const noTimeEnded = await (await fetch(loadTasksUrl_no_time)).json();
        const eventsEnded = await (await fetch(loadTasksUrl_events)).json();
        //
        const inProgress_noTime = await (await fetch(inProgress_loadTasksUrl_no_time)).json();
        const inProgress_events = await (await fetch(inProgress_loadTasksUrl_events)).json();
        //
        setTaskData_open({
            ["Any time"]: { tasks: inProgress_noTime, today: false },
            ...convertAndGroupByDate(inProgress_events, new Date())
        });
        //
        setTaskData_closed({
            ["Any time"]: { tasks: noTimeEnded, today: false },
            ...convertAndGroupByDate(eventsEnded, new Date())
        });
        setTaskData(true);
        //
    }
    useEffect(() => {
        if (wasLoadStarted == false) {
            setLoadStrted(true);
            loadData();
        }
    }, [wasLoadStarted]);
    //
    if (!wasLoadStarted || tasksData === null) {
        return <Loader />
    }
    //
    return <TranslationContextProvider locale={localStorage.getItem("locale") || "en"}>
        <div className="base_background" style={{ height: '100vh', overflowY: 'hidden'}}>
            <div style={{position: 'absolute', top: 0, width: '100%'}}><PageHeader onBackButtonClick={()=> window.history.back() } pageTitle={t('mytasks')} /></div>

            <div className="TaskList base_background" style={{height: 'calc(100vh - 60px)', marginTop: 57}}>

                <h3 style={{ textAlign: 'center' }}>Opened</h3>

                {Object.keys(tasksData_open).map((day, key) => <div key={key}>
                    <div>
                        <span className="_black_bold">{day === 'Any time' ? t('any_time') : day}</span> &nbsp;
                        {tasksData_open[day].today ? <span className="_grey_16">Today</span> : <></>}
                    </div>
                    <br />
                    {tasksData_open[day].tasks.map((task, k) => <div>
                        <TaskCard key={k} task={task}
                            updateTasks={() => {
                                loadData();
                            }}
                            link={familyUrl + (task.type === "no_time" ? '/tasks/' : '/events/') + (task.type === "no_time" ? task.id : task.id)}
                        />
                    </div>)}
                    <br />
                </div>)}


                <h3 style={{ textAlign: 'center' }}>Done</h3>


                {Object.keys(tasksData_closed).map((day, key) => <div key={key}>
                    <div>
                        <span className="_black_bold">{day === 'Any time' ? t('any_time') : day}</span> &nbsp;
                        {tasksData_closed[day].today ? <span className="_grey_16">Today</span> : <></>}
                    </div>
                    <br />
                    {tasksData_closed[day].tasks.map((task, k) => <div>
                        <TaskCard key={k} task={task}
                            updateTasks={() => {
                                loadData();
                            }}
                            link={familyUrl + (task.type === "no_time" ? '/tasks/' : '/events/') + (task.type === "no_time" ? task.id : task.id)}
                        />
                    </div>)}
                    <br />
                </div>)}
            </div>

        </div>
    </TranslationContextProvider>
}
