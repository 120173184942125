import { useState } from "react";
import ButtonPrimary from "../basePageComponents/buttons/ButtonPrimary";
import ButtonSecondary from "../basePageComponents/buttons/ButtonSecondary";
import TextInput from "../basePageComponents/inputs/TextInput";
import "./UnassignMeConfirm.css";
import PageHeader from "../basePageComponents/PageHeader";
import { CategoryImages, CategoryLabelIcons } from '../basePageComponents/images/CategoryImage';
import CircleImage from '../basePageComponents/images/CircleImage';

export default function UnassignMeConfirm({ onConfirm = (txt) => null, onCancel = () => null, task = {} }) {
    const [text, setText] = useState('');
    console.log("TASK OBJECT", task);
    return <div className="full_window base_background">
        <PageHeader onBackButtonClick={onCancel} pageTitle="Withdraw" backButtonText="Cancel"/>
        <div className="base_background " style={{ padding: 20 }}>

            <div className="taskCardThumb">
                <div className="taskCardThumb_text">
                    <div className="_black_bold_18">{task.title}</div>
                    <div style={{paddingTop: 6}} className="_black">{task.local_start_at.split(',')[0]}</div>
                    <div style={{paddingTop: 4}} className="_grey_16">{task.local_start_at.split(',')[1].substr(0,6)} - {task.local_end_at.split(',')[1].substr(0,6)}</div>
                </div>
                <div className="taskCardThumb_image">
                    <CircleImage src={CategoryImages[task.category]} size={58} />
                </div>
            </div>

            <div className="_black_20"
                style={{ paddingTop: 10, paddingBottom: 10, width: 'calc(100% - 40px)' }}>
                Are you sure you want to unassign
                yourself from this task?
            </div>

            <TextInput
                value={text}
                onChange={(e) => setText(e.target.value)}
                labelText="If yes, please describe the reason:"
                placeholder="Can you tell us why?"
            />
            <br />
            <div style={{ width: 250, position: 'absolute', right: 0 }}>
                <ButtonPrimary onClick={()=> onConfirm(text)} txt="Withdraw" />
            </div>
        </div>
    </div>;
}
/*
<img style={{ borderRadius: 24 }} width={58} height={58} alt="[img]" />
*/