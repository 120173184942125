export default {
    welcomeBack: 'ברוך שובך!',
    myTasks: 'המשימות שלי',
    helloName: 'שלום {name}!',
    thanksForFamily: 'תודה שהצטרפת לתמוך במשפחת {family_name}.',
    alwaysReady: 'תמיד מוכן לעזור!',
    progressBar: '{part} מתוך {total} משימות שנתפסו',
    allTasks: 'כל המשימות',
    datelessTasks: 'משימות ללא תאריך',
    iWillTakeIt: "אני על זה!",
    with_date: 'עם תאריך',
    dateless: 'ללא תאריך',
    thanksForCaring: 'מילים לא יכולות לבטא את ההוקרה שלנו. המעשים שלך מדברים בעד עצמם. תודה!',
    percent38: '',
    setSms: 'שלח לי תזכורת ב-SMS',
    addCalendar: 'הוסף ליומן',
    cancel: 'ביטול',
    submit: 'שלח',
    monday: 'ב׳',
    tuesday: 'ג׳',
    wednesday: 'ד׳',
    thursday: 'ה׳',
    friday: 'ו׳',
    saturday: 'ש׳',
    sunday: 'א׳',


    january: "ינואר",
    february: "פברואר",
    march: "מרץ",
    april: "אפריל",
    may: "מאי",
    june: "יוני",
    july: "יולי",
    august: "אוגוסט",
    september: "ספטמבר",
    october: "אוקטובר",
    november: "נובמבר",
    december: "דצמבר",

    translate: "תרגם",
    refuseYesNo: "האם אתה בטוח שאתה רוצה לסרב?",
    refuseIfYes: "אם כן, אנא תאר למה בתיבה למטה.",
    yourAnswer: "תשובתך",
    back: "חזור",
    confirm: "אישור", // אישור
    areYouSure: "האם אתה בטוח?",
    didYouComplete: "האם באמת השלמת את המשימה?",
    yes: "כן",
    no: "לא",

    noTaskThis: "אין משימות בתאריך שנבחר זמינות. גם אין משימות ללא תאריך",
    noTaskTaken: "לא נלקחו משימות. אנא עבור לדף המשימות הראשי וקח כמה משימות",
    noTaskAvailable: "אין משימות זמינות. אנא בקש מבעל המשפחה ליצור משימות למשפחה",

    errLoginTitle: "אנא התחבר",
    errLoginDescription: "דף זה דורש אימות. אנא התחבר דרך SMS",
    errAuthTitle: "אימות שגוי",
    errAuthDescription: "דף זה דורש אימות אך האסימונים השמורים אינם תקפים. נסה להתחבר שוב דרך SMS",
    errLoadTitle: "שגיאה בדף",
    errLoadDescription: "משהו בלתי צפוי קרה. אנא צור קשר עם התמיכה",
    errNetworkTitle: "לא זמין",
    errNetworkDescription: "אנא בדוק את חיבור האינטרנט שלך וטען מחדש את הדף",
    errServerTitle: "משהו לא תקין בשרת",
    errServerDescription: "אנא נסה לטעון מאוחר יותר",

    decline: "לדחות",
    done: "בוצע",
    onlyDatelessStr: "אין משימה ליום הנוכחי, אבל אתה יכול לעשות זאת היום:",
    datelessStr: "אתה יכול גם לעשות זאת היום:",
    viewDetails: "צפה בפרטים",
    taken: "נלקח",
    noDescriprion: "אין תיאור",
    tryAgain: "נסה שוב",
    happyJourney: "שמח שהצטרפת למסע שלנו",
    notForMe: "לא בשבילי",
    mytasks: "המשימות שלי",
    signUp: "הרשמה",
    wNumber: "מהו מספר הטלפון שלך?",
    wName: " מה שמך?",
    greatTo: "נהדר שאתה כאן",
    anyHelp: "כל עזרה שתספק תעשה הבדל",

    scrollToAgree: 'גלילה לאישור',
    ok_scroll: 'אישור והמשך',

    howCanI: 'איך אני יכול לעזור',
    anyTaskTake: 'אפילו אם תקחו דבר קטן ,זה יעזור מאד ',
    noTaskYet: 'בינתיים אין משימות',

    welcomeBackName: 'ברוך שובך {first_name}!',
    helpingToFamily: 'תמיכה ב ',

    goBack: 'תחזור',

    completed_: "הושלם",
    unassigned_: "לא הוקצה",
    taken_: "נלקח",

    notiTaskCompleted: 'המשימה "{taskName}" הושלמה',
    notiTaskUnassigned: 'המשימה "{taskName}" בוטלה',
    notiTaskTaken: 'המשימה "{taskName}" נלקחה',

    set_: 'כדי להגדיר',
    pleasePickTime: 'בחר זמן',
    pickDate: 'נא להזין תאריך',
    pickTime: 'ציין זמן',
    itsTimeFor: "הגיע הזמן ללוח השנה של אפל/גוגל",

    signingIn_1: 'הכניסה מהווה אישור שקראת את ',
    termsOfUse: 'תנאי השימוש',
    signingIn_2: ' ',
    privacyPolicy: 'ומדיניות הפרטיות',
    signingIn_3: " של אנולה, שנועדה לשמור על המשפחה ולייעל את התמיכה עבורה ",

    thisIsYourTask: 'זו המשימה שלך',

    switchAccounts: 'החלפת חשבונות',
    switch: 'החלף',
    logout: 'להתנתק',
    current: 'נוֹכְחִי',
    help: 'עֶזרָה',

    monday_: 'ב',
    tuesday_: 'ג',
    wednesday_: 'ד',
    thursday_: 'ה',
    friday_: ' ו',
    saturday_: ' ש',
    sunday_: 'א',
    family: 'מִשׁפָּחָה',

    signUpRequestError: 'שגיאת בקשת הרשמה',
    pleaseEnterValidPhone: 'נא להזין מספר טלפון קיים חוקי',
    youAreRegistered: 'מכיוון שאת/ה כבר חלק ממעגל התמיכה של המשפחה, קישור ייעודי נשלח אליך במסרון',

    showInMyTasks: 'צפייה במשימות שלי',

    category_home: 'ביתי',
    category_medical: 'רפואי',
    category_childcare: 'טיפול בילדים',
    category_finance: 'כלכלי',
    category_legal_rights: 'חוקי',
    category_legal: 'חוקי',
    category_emotional: 'רגשי',
    category_general: 'כְּלָלִי',

    menu_all_tasks: 'כל המשימות',
    menu_my_tasks: 'המשימות שלי',
    menu_profile: 'פרופיל',

    widget_tasks_available: 'משימות זמינות',
    widget_tasks_taken: 'משימות שנלקחו',

    neverLoseThisPage: 'אל תאבדו את הדף הזה ואת האפשרות להגיש עזרה.',
    install_iOS: 'הורד אפליקציה',
    install_Android: 'הורד Apk',
    install_PWA: 'התקן PWA',

    button_MyTask: 'שלי',
    button_Today: 'היום',

    install_App: 'התקן את האפליקציה שלנו.',

    language: 'שפה',
    inspiredBy: 'בהשראת',

    thankForChoosing: 'תודה שבחרתם לתמוך במשפחת \n{family_name}',

    theFamilyMappedOutTasks: 'המשפחה מיפתה משימות שיכולות לתמוך בה, כל שנשאר לך זה להיכנס ולבחור משימות.',

    everySmallTask: 'כל משימה קטנה היא הקלה גדולה.',
    letsStart: 'בואו נתחיל',
    weEstablishedEnolaTo: 'הקמנו את אנולה כדי לעזור למשפחות בתקופות מאתגרות להפעיל בצורה יעילה את רשת התמיכה שסביבן.',
    thankYouForWantedToSupport: 'תודה שאתם רוצים לתמוך במשפחת {family_name}',
    wasSentToFamilly: '{full_name}, בקשתך להצטרף למעגל התמיכה נשלחה למשפחת {family_name}. בשל הרגישות של הזמן הזה, זה עשוי לקחת זמן.',
    //
    continue: 'המשך', //
    supportForTheFamily: 'תמיכה במשפחת {family_name}',
    yourRequestSentToTheFamily: 'בקשתך להצטרף למעגל התמיכה נשלחה למשפחה. תודה רבה על הדאגה שלך :)',
    //
    youShouldRegister: 'כדי לשמור על פרטיות המשפחה, עליך להירשם למערכת זו.',
    afterRegistration: 'לאחר ההרשמה תוכל לקחת משימה לאחר אישור מהמשפחה.',
    someTasksFrom: 'כמה משימות מ ',


    regConditions: 'רגע לפני שנתחיל, בגלל הרגישות של הפעילות שלנו, בבקשה קחו רגע לקרוא ולאשר את תנאי השימוש ומדיניות הפרטיות שלנו, שנועדה לשמור על המשפחה ולמקסם את התמיכה בה. לחיצה על הכפתור מאשרת שקראת והסכמת.',

    regConditions_0: 'לפני שנתחיל, בשל הרגישות של הפעילות שלנו, אנא הקדישו רגע לקרוא ולאשר את ה',
    regConditions_url_0: 'תנאי השימוש',
    regConditions_1: ' ו',
    regConditions_url_1: 'מדיניות הפרטיות',
    regConditions_2: ' שלנו שנועדו להגן על המשפחה ולמקסם את התמיכה בה. לחיצה על הכפתור מאשרת שקראתם והסכמתם.',

    thisRequiresAuth: 'עמוד זה דורש אימות.',
    demoAccount: 'חשבון הדגמה',

    iCannotTake: 'לא אוכל לקחת את המשימה',
    scheduledFor: 'נקבעה ל:',
    taskToCalendar: 'כדאי להוסיף את המשימה ליומן כדי לא לשכוח.',
    iWillTake: 'לקחתי את המשימה',

    sendMessage: 'שלחו הודעה',
    sendWhatsApp: 'שלחו הודעת וואטסאפ',
    forMoreDetails: 'לפרטים נוספים:',

    tasks: 'משימות',

    tab_all: 'הכל',
    tab_available: 'זמין',
    tab_my: 'שלי',
    finished: 'הושלם',

    profile_takenTasks: 'משימות שנלקחו',
    profile_CompletedTasks: 'משימות שהושלמו',
    profile_logout: 'התנתק מהמכשיר הזה',
    profile_allMy: 'כל המשימות שלי',

    iWillDo: 'אני אעשה את זה',
    description: 'תיאור',

    calendarItalic: 'כדי לוודא שלא תשכח, הוסף את המשימה הזאת ללוח השנה שלך',

    tasks_Taken: 'נלקח',

    any_time: 'בכל זמן',

    contactForLink: 'צרו קשר עם בעל המשפחה וקבלו את קישור ההרשמה',
    signUpFillTheForm: 'הרשמה (מלא את הטופס לאחר לחיצה על קישור ההרשמה, תוכל לראות כמה משימות של המשפחה לפני מילוי הטופס)',
    clickOnLoinLink: 'לחץ על קישור הכניסה (יישלח אחרי אישור ההרשמה בהודעת SMS) (אם האפליקציה מותקנת, תתחבר אוטומטית לאפליקציה)',
    ifTryDemo: 'אם תרצה לנסות את האפליקציה ללא הרשמה, תוכל להשתמש בחשבון הדגמה (פשוט לחץ על הכפתור למטה)',
    yHaveTo: 'אתה חייב:'

} as const;
