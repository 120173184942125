import './TextField.css'
import { useState } from 'react';
import TextFieldType from '../enums/TextFieldTypes';
const TextField = ({ name, placeholder, value,codeValue, onChange, onCodeChange, type }) => {
    
    const [phone_num, setPhoneNum] = useState("");
    const [phone_code, setPhoneCode] = useState("+972");
    if (type == TextFieldType.TEXT) {
        return (
            <div style={{ width: '100%', maxWidth: '100%', padding: 0, margin: 0 }}>
                <input
                    
                    className='TextField'
                    required
                    placeholder={placeholder}
                    type="text"
                    name={name}
                    value={value}
                    onChange={onChange}
                />
            </div>
        );
    } else if (type == TextFieldType.PHONE){
        
        // onChange={onChange}
        //onChange({target:{"name":"phone", "value":phone_code+phone_num,}});
        return <div style={{ width: '100%', maxWidth: '100%', padding: 0, margin: 0 }}>
            <table style={{width: '100%'}}><tbody>
                <tr>
                    <td style={{width: 75}}>
                        <select name='phone_code' 
                        value={codeValue}
                        onChange={onCodeChange}
                        className='TextField'>
                            <option>+972</option>
                            <option>+375</option>
                            <option>+1</option>
                        </select>
                    </td>
                    <td>
                        &nbsp;&nbsp;
                    </td>
                    <td><input
                    className='TextField'
                    required={true}
                    placeholder={placeholder}
                    type="number"
                    name={name}
                    onChange={onChange}
                    value={value}
                    min={99}
                    max={99999999999999999999}
                /></td>
                </tr>    
            </tbody></table>
                
            </div>
    }
};

export default TextField;
