import { useState } from 'react';
import { AccountImage } from '../basePageComponents/images/AccountImage';
import { CategoryImages, CategoryLabelIcons } from '../basePageComponents/images/CategoryImage';
import CircleImage from '../basePageComponents/images/CircleImage';
import './TaskCard.css';
import TaskDetailsMyTask from './TaskDetailsMyTask';
import TaskDetails from './TaskDetails';
import t from '../translations/translate.ts';
import { NotificationManager } from 'react-notifications';

/*
if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                    || navigator.userAgent.match(/Macintosh/i)) {


                                    window.location.href = (generateAppleLink(
                                        response.data.title,
                                        isEvent ? dateStart : new Date(),
                                        isEvent ? dateEnd : new Date(),
                                        response.data.description,
                                        "Enola family",
                                        isEvent ? taskId : -(taskId),
                                        localStorage.getItem("login_familyToken")
                                    ));

                                } else {
                                    window.open(generateGoogleCalendarURL(
                                        response.data.title,
                                        dateStart,
                                        dateEnd,
                                        response.data.description,
                                        "Enola family",
                                        isEvent ? taskId : -(taskId),
                                        localStorage.getItem("login_familyToken")
                                    ), '_blank');
                                }
*/

export default function TaskCard({
    task = {}, link, updateTasks = () => null
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const isTaken = task.status != "initial";
    if(task.status === "completed" && task.assigned_on_me){
        console.log('completed open');
    } else if (task.assigned_on_me && task.status != "initial" && dialogOpen) {
        return <TaskDetailsMyTask closeAndUpdate={() => {
            setDialogOpen(false);
            updateTasks();
        }} link={link} task={task} onClose={() => setDialogOpen(false)} />;
    } else if (dialogOpen && task.status === "initial") {
        return <TaskDetails
            closeAndUpdate={() => {
                setDialogOpen(false);
                updateTasks();
            }}
            link={link} task={task} onClose={() => setDialogOpen(false)} />
    } else {
        // do nothing if taken task
    }
    //connect task details
    return <div onClick={() => setDialogOpen(true)} className={task.assigned_on_me && isTaken ? "TaskCard no_select TaskCard_MyTask" :
        (isTaken ? "TaskCard no_select TaskCard_Taken" : "TaskCard no_select")}>
        <div style={{ width: 'calc(100% - 60px)' }}>
            {task.assigned_on_me ? <>

                <div style={{ display: 'inline-block' }}>
                    <img width={16} height={16} src={AccountImage.Profile} alt='person' />
                </div>
                <div style={{ display: 'inline-block', marginTop: -1 }}>
                    &nbsp;
                    { t('button_MyTask')}
                    {task.status === "completed" ?<span>&nbsp; |&nbsp; {t('completed_')}</span>:<></>}
                </div>
                <br />
            </> : <>
                {isTaken ? <>

                    <div style={{ display: 'inline-block' }}>
                        <img width={16} height={16} src={AccountImage.Person} alt='person' />
                    </div>
                    <div style={{ display: 'inline-block', marginTop: -1 }}>
                        &nbsp;
                        {t('tasks_Taken')}
                    </div>
                    <br />
                </> : <>
                    <img width={16} height={16} src={CategoryLabelIcons[task.category]} alt='category' />
                    &nbsp;
                    <span>{t( 'category_' + task.category)}</span><br />
                </>}

            </>}

            <span className='_black_bold_18'>{task.title}</span><br />
            <span className='_grey_16'>{task.type == "no_time" ? t('any_time') : task.local_start_at.split(' ')[1].slice(0, 5)}</span>
        </div>
        <div style={{ width: 58, height: 58 }}>
            <CircleImage src={CategoryImages[task.category]} size={58} />
        </div>
        <br />
    </div>
}

// <code style={{userSelect: 'all'}}>{link}</code>
