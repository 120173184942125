import { TranslationContextProvider } from 'schummar-translate/react';
import ButtonPrimary from '../basePageComponents/buttons/ButtonPrimary';
import './WelcomeDetails.css';
import t from '../translations/translate.ts';
import TextField from '../fields/TextField.js';
import TextFieldType from '../enums/TextFieldTypes.js';
import logo from '../images/logo_thumb.png';

export default function WelcomeThanks({ familyName }) {
    //const familyName = "The Cohen";
    return <TranslationContextProvider locale={localStorage.getItem("locale")}>
        <div className="base_background WelcomeDetails_root">
            <img className='WelcomeLogo' src={logo} />
            <div style={{ height: 20 }} />
            <div className="WelcomeDetails_form">
                <span className='_black_20_bold'>Thanks for joining the support circle for {familyName} family</span>
                <br/><br/>
                <span className='_grey_16'>
                    You'll be receiving messages soon with open needs and tasks. Every bit of help is appreciated🙏
                </span>
            </div>
        </div>
    </TranslationContextProvider>;
}
