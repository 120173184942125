import './PageHeader.css';
import back_icon from './images/back_image_icon.svg';
export default function PageHeader({
    pageTitle, showBackButton = true, onBackButtonClick = () => null, backButtonText = ''
}) {
    return <div className="AppPageHeader">
        <div className='btn_div no_select' style={{verticalAlign: 'top', marginTop: 15}}>
            <button onClick={onBackButtonClick} style={{ cursor: 'pointer', backgroundColor: '', margin: 0, height: 34, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                <div style={{display: 'inline-block', width: 24, height: 24, margin: 0, padding: 0}}><img src={back_icon} width={24} height={24}/> </div>
                <div style={{display: 'inline-block',  height: 24, margin: 0, padding: 0, paddingTop: 7, verticalAlign: 'top'}} className='_green'>&nbsp; {backButtonText}</div>
            </button>
        </div>
        <div className='ttl_div _black_20'> {pageTitle} </div>
    </div>
}
/*

*/