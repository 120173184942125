import ButtonPrimary from "../buttons/ButtonPrimary";
import logo from '../images/logo.png';
import welcome_png from '../images/sign_up_welcome.png';
import partner_logo from '../images/partner_logo.svg';
import flower_in_hand from '../images/flower_in_hand.svg';
import { useState } from "react";
import Loader from "../Loader";
import { TranslationContextProvider } from 'schummar-translate/react';
import t from '../translations/translate.ts';
import WelcomeTo from "./WelcomeTo.js";

export default function SignUpWelcomePage({ familyName, letsGo }) {
    const [welcomeState, setWelcomeState] = useState(1);
    setTimeout(() => {
        document.getElementsByClassName("bottomMenu_1")[0].style.display = "none";
        document.getElementById("route_main_div_with_bottom_menu").style.marginBottom = '0';
    }, 100);
    if (welcomeState == 1) {
        return <WelcomeTo familyName={familyName} onClick={()=> letsGo()} />
    } else if (welcomeState == 2) {
        return <TranslationContextProvider locale={localStorage.getItem("locale")}>
            <div 
            style={{ overflowY: 'scroll', 
                direction: localStorage.getItem('locale') == 'iw' ? 'rtl':'ltr',
                overflowX: 'hidden', height: ' calc(100%)', userSelect: 'none' }}
             onContextMenu={(e) => e.preventDefault()}>
            <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
                <img src={logo} style={{ width: 180 }} alt="logo" />
            </div>
            <div style={{ paddingTop: 20, width: 240, margin: 'auto', paddingBottom: 20 }}>
                <img style={{ width: 240 }} src={flower_in_hand} alt="hand" />
            </div>
            <div style={{ width: 280, margin: 'auto', color: 'black' }}>
                {t('theFamilyMappedOutTasks')}
                    <br /><br />
                    {t('everySmallTask')}
                    <br /><br />
                <ButtonPrimary onClick={letsGo}>{t('letsStart')}</ButtonPrimary>
                <br /><br />
            </div>
        </div></TranslationContextProvider>;
    } else return <Loader />;
}


/*
{familyName} mapped the tasks that can support her, now all you have to do is choose a task.
The family mapped the tasks that can support her, now all you have to do is choose a task.
            <br /><br />
            Every small task is a huge help

*/

/*

<TranslationContextProvider locale={localStorage.getItem("locale")}>Welcome to ENOLA  Welcome to ENOLA
            <div
              style={{ 
                direction: localStorage.getItem('locale') == 'iw' ? 'rtl':'ltr',
                overflowY: 'scroll', overflowX: 'hidden', 
                height: ' calc(100%)', userSelect: 'none'
             }} 
              onContextMenu={(e) => e.preventDefault()}
              
            >
            <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
                <img src={logo} style={{ width: 170 }} alt="logo" />
            </div>

            
            <div style={{ paddingTop: 20, width: 210, margin: 'auto', paddingBottom: 15 }}>
                <img style={{ width: 200 }} src={welcome_png} alt="hand" />
            </div>
            <div style={{ width: 280, margin: 'auto', color: 'black' }}>
                <b style={{ whiteSpace: 'pre'}}>{t('thankForChoosing', { family_name: familyName })}</b><br />
                <div style={{textAlign: localStorage.getItem('locale') == 'iw'? 'right':'left'}}>
                {t('weEstablishedEnolaTo')}
                <br /><br />
                {t('theFamilyMappedOutTasks')}
                <br />
                </div>
                <table style={{ width: 140, margin: 'auto' }}>
                    <tbody>
                        <tr>
                            <td><div style={{ height: 20 }}>{t('inspiredBy')}</div></td>
                            <td><img style={{ height: 40, width: 40 }} src={partner_logo} /></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <ButtonPrimary onClick={letsGo}>{t('continue')}</ButtonPrimary>
                <br /><br />
            </div>
        </div></TranslationContextProvider>

*/