import ButtonPrimary from "../basePageComponents/buttons/ButtonPrimary";
import ButtonSecondary from "../basePageComponents/buttons/ButtonSecondary";
import { CategoryImages } from "../basePageComponents/images/CategoryImage";
import PageCart from "../basePageComponents/PageCart";
import PageHeader from "../basePageComponents/PageHeader";
import radio_selected from '../basePageComponents/images/radio_btn_selected.svg';
import radio_not_selected from '../basePageComponents/images/radio_btn_not_selected.svg';
import { useState } from "react";
import { ContactImage } from "../basePageComponents/images/ContactImage";
import generateAppleLink from "../AppleCalendar.ts";
import generateGoogleCalendarURL from "../GoogleCalendar";
import { NotificationContainer, NotificationManager } from "react-notifications";
import t from '../translations/translate.ts';
import './TaskDetails.css';

export default function TaskDetails({ onClose = () => null, task = {}, link = '#task_link', closeAndUpdate = () => null }) {
    const [toCalendar, setToCalendar] = useState(false);
    const calendarLink = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i)) ?
        generateAppleLink(task.title, task.type === "no_time" ? new Date() : task.task_date,
            task.type === "no_time" ? new Date() : task.task_date,
            task.description, 'Enola App Task', task.id, localStorage.login_familyToken) :
        generateGoogleCalendarURL(task.title,
            task.type === "no_time" ? new Date() : task.task_date, task.type === "no_time" ? new Date() : task.task_date,
            task.description, 'Enola App Task', task.id, localStorage.login_familyToken);
    const taskPhone = task.creator.formatted_phone_number.replaceAll('-', '').replaceAll(' ', '');
    const taskSmsLink = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/Macintosh/i)) ?
        "sms:" + taskPhone :
        "sms:" + taskPhone + "?body=" + encodeURIComponent(
            `Hello, ${task.creator.full_name}! I have some questions about task "${task.title}"`);
    const taskWhatsAppLink = "https://wa.me/" + taskPhone.replaceAll("+", "")
        + "/?text=" + encodeURIComponent(
            `Hello, ${task.creator.full_name}! I have some questions about task "${task.title}"`);
    return <div className="full_window base_background">
        <PageHeader onBackButtonClick={onClose} pageTitle={task.title} />
        <div style={{
            backgroundColor: '',
            position: 'relative',
            height: 'calc(100vh - 220px)',
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingBottom: 20
        }} >

            <div style={{
                margin: 'auto', width: 'calc(100% - 50px)',
                marginTop: 20, marginBottom: 20,
                borderRadius: 24, overflow: 'hidden'
            }}>
                <img style={{ padding: 0, margin: 0, margin: 'auto', borderRadius: 24 }} width={'100%'} height={148} src={CategoryImages[task.category]} alt='img' />
            </div>

            {task.type === "no_time" ? <></> : <>
                <PageCart content={<span>
                    <span className="_black_bold">Start at:</span><br />
                    <span className="_black">{task.local_start_at}</span>
                    <br />
                    <span className="_black_bold">End at:</span><br />
                    <span className="_black">{task.local_end_at}</span>
                </span>} />
            </>}

            <PageCart content={<span>
                <span className="_black_bold">{t('description')}</span><br />
                <span className="_black">{task.description}</span>
            </span>} />

            <PageCart content={<span>
                <span className="_grey_16">{t('forMoreDetails')}</span><br />
                <span style={{ lineHeight: 2, color: '#313E44' }} className="_black_bold">{task.creator.full_name}</span><br />
                <a target="_blank" style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} href={`tel:${taskPhone}`} className="_green">
                    <img src={ContactImage.Phone} alt="contact" />
                    &nbsp; {taskPhone}
                </a><br />
                <a target="_blank" href={taskSmsLink} style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} className="_green">
                    <img src={ContactImage.SMS} alt="contact" />
                    &nbsp; {t('sendMessage')}
                </a><br />
                <a target="_blank" style={{ paddingTop: 6, paddingBottom: 6, lineHeight: 2 }} href={taskWhatsAppLink} className="_green">
                    <img src={ContactImage.WhatsApp} alt="contact" />
                    &nbsp; {t('sendWhatsApp')}
                </a><br />
            </span>} />

            <PageCart onClick={() => {
                console.log('to calendar', toCalendar);
                setToCalendar(!toCalendar);
            }} content={<div>

                <div style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}>
                    <span>{t('addCalendar')}</span> <br />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', width: 50, textAlign: 'center' }}>
                    <img src={toCalendar ? radio_selected : radio_not_selected} alt="checkbox" />
                </div>
                <br />
                <i>
                    {t('calendarItalic')}
                </i>

            </div>} />

        </div>
        <div className="TaskDetails_footer">
            <ButtonPrimary width="calc(100% - 25px)" txt={t('iWillDo')} onClick={async () => {
                const takeLink = link + '/assign_me';
                console.log(takeLink);
                await fetch(takeLink, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                });
                //
                if (toCalendar) {
                    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                        || navigator.userAgent.match(/Macintosh/i)) {
                        window.location.href = calendarLink;
                    } else {
                        window.open(calendarLink, '_blank');
                    }
                }
                //
                NotificationManager.success(`Task "${task.title}" taken successfully`, "Task taken", 2000);
                setTimeout(() => closeAndUpdate(), 100);
            }} />
            <ButtonSecondary width="calc(100% - 25px)" onClick={onClose} txt={t('cancel')} />
        </div>
        <NotificationContainer />
    </div>
}