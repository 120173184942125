import { useEffect, useState } from "react";
import PageTab from "./basePageComponents/tabs/PageTab";
import { AccountImage } from "./basePageComponents/images/AccountImage";
import './home.css';
import TaskCard from "./tasksPages/TaskCard";
import FamilySwitch from "./FamilySwicth";
import CONSTS from "./Constants";
import Loader from "./Loader";
import { NotificationContainer } from "react-notifications";
import isDemoEnabled from "./demo_mode/is_demo_enabled";
import ErrorPage from "./ErrorPage";
import LOAD_ERROR_TYPES from "./enums/LoadErrorTypes";
import DemoAccount from "./demo_mode/demo_account";
import { TranslationContextProvider } from "schummar-translate/react";
import t from './translations/translate.ts';

function getQueryParams() {
    const params = {};
    const queryString = window.location.search.substring(1);
    const pairs = queryString.split("&");
    pairs.forEach(pair => {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
    });
    return params;
}

export function convertAndGroupByDate(maintenanceRepairs, todayDate) {
    function compareDates(date1, date2) {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        if (date1 > date2) {
            return false;
        } else if (date1 < date2) {
            return false;
        } else {
            return true;
        }
    }
    function convertToDate(dateString, timeString) {
        return new Date(dateString + 'T' + timeString);
    }
    function convertToLocal(dateString, timeString) {
        const utcDate = convertToDate(dateString, timeString);
        return utcDate.toLocaleString('ru-RU');
    }
    return maintenanceRepairs.reduce((acc, item) => {
        const localStartAt = convertToLocal(item.date, item.start_at);
        const localEndAt = convertToLocal(item.date, item.end_at);
        const localDate = localStartAt.split(',')[0];
        if (!acc[localDate]) {
            acc[localDate] = { tasks: [], today: compareDates(todayDate, convertToDate(item.date, item.start_at)) };
        }
        acc[localDate].tasks.push({
            task_date: convertToDate(item.date, item.start_at),
            local_start_at: localStartAt,
            local_end_at: localEndAt,
            ...item
        });
        return acc;
    }, {});
}

function t__() {
    return {
        "id": -1,
        "type": "no_time",
        "title": "Lorem Ipsum",
        "description": null,
        "category": "emotional",
        "category_slug": null,
        "status": "initial",
        "creator":
        {
            "full_name": "QA Hleb",
            "formatted_phone_number": "+375 44 522-57-41"
        }
    }
}

const TaskTab = {
    AllTasks: t('tab_all'),
    Available: t('tab_available'),
    MyTasks: t('tab_my'),
    //FinihedTasks: t('completed_')
};

const TaskCounterKeys = {
    AllTasks: ({ completed, in_progress, initial }) => {
        return completed + in_progress + initial;
    },
    Available: ({ initial }) => initial,
    MyTasks: ({ my_tasks }) => my_tasks,
    FinihedTasks: ({ completed }) => completed
};

export default function Home() {
    const CurrentDate = new Date();
    const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");
    const { tab } = getQueryParams();
    //
    if (!localStorage.getItem("login_familyToken") && !isDemoEnabled()) {
        return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
    }
    //
    const profileUrl = `${CONSTS.BASE_API_URL}/supporters/${localStorage.getItem("login_userToken")}`;
    const familyUrl = `${profileUrl}/families/${localStorage.getItem("login_familyToken")}`;
    const taskUrl = `${familyUrl}/tasks`;
    //
    const [isSwitchOpen, setSwitchOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(TaskTab[tab] || TaskTab.AllTasks);
    const [profileData, setProfileData] = useState(null);
    const [familyData, setFamilyData] = useState(null);
    const [wasLoadStarted, setLoadStrted] = useState(false);
    const [wasError, setError] = useState(null);
    //
    const [taskAllData, setTaskAllData] = useState(null);
    //
    async function loadData() {
        try {
            if (isDemoEnabled()) {
                const d = new DemoAccount();
                setFamilyData(d.family);
                setProfileData(d.supporter);
                setTaskAllData({
                    no_time: d.no_time,
                    events: d.events
                });
            } else {
                console.log("requests started");
                setProfileData(await (await fetch(profileUrl)).json());
                setFamilyData(await (await fetch(familyUrl)).json());
                const loadTasksUrl_no_time = `${profileUrl}/tasks?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=completed`;
                const loadTasksUrl_events = `${profileUrl}/my_events?family_token=${localStorage.getItem("login_familyToken")}&status_in[]=completed`;
                if (selectedTab === TaskTab.FinihedTasks) {
                    console.log("finished will be loaded");
                    setTaskAllData({
                        no_time: await (await fetch(loadTasksUrl_no_time)).json(),
                        events: await (await fetch(loadTasksUrl_events)).json(),
                    });
                } else {
                    setTaskAllData(await (await fetch(taskUrl)).json());
                }
                console.log("requests finished");
            }
        } catch (e) {
            console.log(e);
            setError(true);
        }
    }

    useEffect(() => {
        if (wasLoadStarted == false) {
            setLoadStrted(true);
            loadData();
        }
    }, [wasLoadStarted]);
    //
    if (wasError) {
        return <ErrorPage errType={LOAD_ERROR_TYPES.PAGE_LOAD_ERROR} />
    }
    //
    if (!wasLoadStarted || profileData == null || familyData == null || taskAllData == null) {
        return <Loader />
    }
    console.log(profileData);
    console.log(familyData);
    //
    var taskFiltered = taskAllData;
    //
    if (selectedTab === TaskTab.MyTasks) {
        // filter: task.assigned_on_me === true
        taskFiltered = {
            no_time: taskAllData.no_time.filter((task) => task.assigned_on_me && task.status != "initial"),
            events: taskAllData.events.filter((task) => task.assigned_on_me && task.status != "initial")
        }
    } else if (selectedTab === TaskTab.Available) {
        taskFiltered = {
            no_time: taskAllData.no_time.filter((task) => task.status === "initial"),
            events: taskAllData.events.filter((task) => task.status === "initial")
        }
    }
    //
    const taskDataGrouped = convertAndGroupByDate(taskFiltered.events, CurrentDate);
    //
    const tasksData = {
        ["Any time"]: { tasks: taskFiltered.no_time, today: false },
        ...taskDataGrouped
    };
    //
    console.log(tasksData);
    //
    return <TranslationContextProvider locale={locale}><div className="HomePage">
        <div>
            <div>
                <div className="_black_20_bold no_select"
                    onClick={() => setSwitchOpen(true)}
                    style={{
                        display: 'inline-block',
                        marginTop: 20, marginLeft: 20, marginRight: 20,
                        width: 'calc(100% - 100px)'
                    }}>
                    <span>{familyData.title}</span>
                    &nbsp;
                    <img src={AccountImage.DropDown} alt="^" />
                </div>
                <div className="no_select" style={{ display: 'inline-block', width: 24, height: 24 }}>
                    <a href="/me"><img src={AccountImage.Profile} alt="avatar" /></a>
                </div>
            </div>
            <div style={{
                height: 1.5, marginTop: 16, marginBottom: 16,
                marginLeft: 20, marginRight: 20,
                width: 'calc(100% - 40px)', backgroundColor: '#F0F0F0'
            }} />
            <nav className="TabList no_select">
                <div style={{ width: 460 }}>
                    {Object.keys(TaskTab).map((tab, key) =>
                        <PageTab tabName={tab} key={key}
                            label_txt={
                                <span>
                                    <span>{TaskTab[tab]}</span>
                                    &nbsp; (<span>{TaskCounterKeys[tab](familyData.tasks_counter)}</span>)
                                </span>
                            }
                            is_selected={TaskTab[tab] == selectedTab} />)}
                </div>
            </nav>
            <div className="TaskList base_background">
                {Object.keys(tasksData).map((day, key) => <div key={key}>
                    <div>
                        <span className="_black_bold">{day === 'Any time' ? t('any_time') : day}</span> &nbsp;
                        {tasksData[day].today ? <span className="_grey_16">Today</span> : <></>}
                    </div>
                    <br />
                    {tasksData[day].tasks.map((task, k) => <div>
                        <TaskCard key={k} task={task}
                            updateTasks={() => {
                                loadData();
                            }}
                            link={familyUrl + (task.type === "no_time" ? '/tasks/' : '/events/') + (task.type === "no_time" ? task.id : task.id)}
                        />
                    </div>)}
                    <br />
                </div>)}
            </div>
        </div>
        <FamilySwitch isOpen={isSwitchOpen} close={() => setSwitchOpen(false)} />
        <NotificationContainer />
    </div>
    </TranslationContextProvider>
};