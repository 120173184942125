import './buttons.css';
import './BackButton.css';
import back_default from '../images/button_back_icons/back_default.png';
import back_hover from '../images/button_back_icons/back_hover.png';
import back_click from '../images/button_back_icons/back_click.png';
const BackButton = ({onClick}) => {
    return(
        <div style={{transform: localStorage.getItem("locale") == "iw"?'rotate(180deg)':''}}><a href='/home' onTouchStart={onClick || function(){window.history.back();} } className='_custom_regular_button BackButton'>
            <img alt='default' src={back_default} className='BackButton_default'/>
            <img alt='click' src={back_click} className='BackButton_click'/>
            <img alt='hover' src={back_hover} className='BackButton_hover'/>
        </a></div>
    )
}
export default BackButton;
