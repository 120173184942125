import default_image from './category_images/General_thumb.jpg';

export default function CircleImage({
    src = default_image, size = 58
}) {
    return <div style={{
        width: size, height: size,
        overflow: 'hidden',
        borderRadius: size / 2.41,
        display: 'inline-block',
        margin: 10
    }}>
        <img alt="photo" src={src} style={{ marginLeft: -size / 2, marginTop: -size / 2 }} />
    </div>
}
