import './TextInput.css';

export default function TextInput(
    {
        onChange = ()=>null, value='', labelText='Input text label',
        placeholder = '', autoFocus = true
    }){
    const inputId = "TextInput_" + Math.random();
    return <div>
        <label className="TextInputLabel" 
        htmlFor={inputId}>{labelText}</label>
        <br/>
        <div style={{marginTop: 10}}><input autoFocus={autoFocus} placeholder={placeholder} className="TextInputField" 
        onChange={onChange} value={value} id={inputId} type="text"/>
        </div>
    </div>;
}
