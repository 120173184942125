import { useState, useEffect } from "react"
import axios from 'axios';
import SignUpState from "../enums/SignUpState"
import SignUpTaskListPage from "./TaskList";
import SignUpWelcomePage from "./Welcome";
import SignUpPendingPage from "./Pending";
import Loader from "../Loader";
import CONSTS from "../Constants";
import SignUpForm from "./SignUpForm";
import ErrorPage from "../ErrorPage";
import LOAD_ERROR_TYPES from "../enums/LoadErrorTypes";
import WelcomeDetails from "./WelcomeDetails";
import WelcomeThanks from "./WelcomeThanks";

export default function SignUpMain({ match }) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [signUpState, setSignUpState] = useState(SignUpState.INITIAL);
    const [familyInfo, setFamilyInfo] = useState(null);
    const familyInviteToken = match.params.token;
    console.log("familyInfo", familyInfo);
    useEffect(() => {
        async function a() {
            console.log("useEffect");
            if (signUpState == SignUpState.INITIAL) {
                try {
                    const response = await axios.post(`${CONSTS.BASE_API_URL}/supporters/signup/validate`, {
                        "invitation": familyInviteToken
                    }, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.data != 200) {
                        throw new Error("Invalid invitation");
                    }
                    const responseFamily = await axios.get(`${CONSTS.BASE_API_URL}/supporters/families/${familyInviteToken}`, {});
                    setSignUpState(SignUpState.WELCOME);
                    setFamilyInfo(responseFamily.data);

                } catch (e) {
                    console.log("error", e);
                }
            }
        }
        a();
    });

    switch (signUpState) {
        case SignUpState.WELCOME:
            return <SignUpWelcomePage familyName={familyInfo.title} letsGo={() => { setSignUpState(SignUpState.TASK_LIST) }} />
        case SignUpState.TASK_LIST:
            return <SignUpTaskListPage familyInfo={familyInfo} letsGo={() => { setSignUpState(SignUpState.SIGN_UP_FORM) }} />
        case SignUpState.SIGN_UP_FORM:
            return <WelcomeDetails 
            familyInviteToken={familyInviteToken} familyInfo={familyInfo}
                firstName={firstName} lastName={lastName}
                setFirstName={setFirstName} setLastName={setLastName} setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber} letsGo={() => { setSignUpState(SignUpState.SIGN_UP_PENDING) }}
            />
            /*return <SignUpForm familyInviteToken={familyInviteToken} familyInfo={familyInfo}
                firstName={firstName} lastName={lastName}
                setFirstName={setFirstName} setLastName={setLastName} setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber} letsGo={() => { setSignUpState(SignUpState.SIGN_UP_PENDING) }}
            />*/
        case SignUpState.SIGN_UP_PENDING:
            return <WelcomeThanks familyName={familyInfo.title} firstName={firstName} lastName={lastName} />
            //return <SignUpPendingPage familyName={familyInfo.title} firstName={firstName} lastName={lastName} />
        case SignUpState.VALIDATION_ERROR:
            return <ErrorPage errType={LOAD_ERROR_TYPES.WRONG_SIGN_UP_TOKEN} />
        default:
            return <Loader />;
    }
}