export default {
    welcomeBack: 'Welcome back', /* any text, it is a comment */
    myTasks: 'My tasks',
    helloName: 'Hello {name}!',
    thanksForFamily: 'Thanks for joining to support {family_name} family.',
    alwaysReady: 'Always ready to help!',
    progressBar: '{part} out of {total} tasks have been taken',
    allTasks: 'All tasks',
    datelessTasks: 'Dateless tasks',
    iWillTakeIt: "I'll take it!",
    with_date: 'with date',
    dateless: 'dateless',
    thanksForCaring: 'Words cannot express our appreciation. Your deeds speak for themselves. Thanks!',
    percent38: ' ',
    setSms: 'SMS me a reminder',
    addCalendar: 'Add to calendar',
    cancel: 'Cancel',
    submit: 'Submit',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'Su',

    monday_: 'Mon',
    tuesday_: 'Tue',
    wednesday_: 'Wed',
    thursday_: 'Thr',
    friday_: 'Fri',
    saturday_: 'Sat',
    sunday_: 'Sun',

    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",

    translate: "Translate",
    refuseYesNo: "Are you sure you want to refuse?",
    refuseIfYes: "If yes, please describe why in the box below.",
    yourAnswer: "Your answer",
    back: "Back",
    confirm: "Confirm",
    areYouSure: "Are you sure?",
    didYouComplete: "Did you actually complete the Task?",
    yes: "Yes",
    no: "No",

    noTaskThis: "No tasks with selected date available. Also no dateless tasks",
    noTaskTaken: "You haven't taken on any tasks. Please go to main task page and take some tasks",
    noTaskAvailable: "No task available. Please ask family owner to create tasks for the family",

    errLoginTitle: "Please login",
    //errLoginDescription: "",
    errAuthTitle: "Wrong auth",
    errAuthDescription: "This page requires authentification but saved tokens are not valid. Try log in via SMS again",
    errLoadTitle: "Error on page",
    errLoadDescription: "Something unexoected hapend. Please contact support",
    errNetworkTitle: "Not available",
    errNetworkDescription: "Please check your Internet connection and reload page",
    errServerTitle: "Something wrong on server",
    errServerDescription: "Please try load later",

    decline: "Decline",
    done: "Done",
    onlyDatelessStr: "No task for current day, but also you can do this today:",
    datelessStr: "Also you can do this today:",
    viewDetails: "View details",
    taken: "Taken",
    noDescriprion: "No description",
    tryAgain: "Try again",
    happyJourney: "Happy you joined\nour journey",
    notForMe: "Quit",
    mytasks: "My tasks",
    signUp: "Sign Up",
    wNumber: "What's your phone number?",
    wName: "What's your name?",
    greatTo: "Great to have you here",
    anyHelp: "Any help you provide,\nwill make a difference",
    scrollToAgree: 'Scroll to agree',
    ok_scroll: 'OK',

    howCanI: 'How can I help',
    anyTaskTake: 'Any task you take will help',
    noTaskYet: 'No tasks yet',

    welcomeBackName: 'Hello {first_name}!',
    helpingToFamily: ' Supporting ',

    goBack: 'Go back',

    completed_: "Completed",
    unassigned_: "Unassigned",
    taken_: "Taken",
    notiTaskCompleted: 'Task "{taskName}" completed',
    notiTaskUnassigned: 'Task "{taskName}" unassigned',
    notiTaskTaken: 'Task "{taskName}" taken',

    set_: 'Set',
    pleasePickTime: 'Please pick time',
    pickDate: 'Pick date',
    pickTime: 'Pick time',
    itsTimeFor: "It's time for Apple/Google Calendar for",

    signingIn_1: 'Signing in constitutes confirmation of the ',
    termsOfUse: 'terms of use',
    signingIn_2: ' and the ',
    privacyPolicy: 'privacy policy',
    signingIn_3: ", which preserve the family's dignity and optimize the help",
    thisIsYourTask: 'This is your task',

    switchAccounts: 'Switch accounts',
    switch: 'Switch',
    logout: 'Logout',
    current: 'current',
    help: 'Help',

    signUpRequestError: 'Sign up request error',
    pleaseEnterValidPhone: 'Please enter valid existing phone number',
    youAreRegistered: 'You are already registered in the family. Please find and click on login link in SMS',
    family: 'Family',

    showInMyTasks: 'Show in my tasks',

    menu_all_tasks: 'All tasks',
    menu_my_tasks: 'My tasks',
    menu_profile: 'Profile',

    button_Today: 'Today',

    widget_tasks_available: 'Tasks available',
    widget_tasks_taken: 'Tasks taken',

    neverLoseThisPage: 'Never lose this page or miss any opportunity to support.',
    install_iOS: 'Get App',
    install_Android: 'Download apk',
    install_PWA: 'Install PWA',
    install_AddToHome: 'Add our app to your home screen.',
    install_App: 'Install our app.',

    button_MyTask: 'My task',

    category_home: 'Home',
    category_medical: 'Medical',
    category_childcare: 'Childcare',
    category_finance: 'Finance',
    category_legal_rights: 'Legal rights',
    category_emotional: 'Emotional',

    
    category_legal: 'Legal rights',
    category_general: 'General',

    language: 'Language',

    demoAccount: 'Demo account',
    thisRequiresAuth: 'This page requires authentification.',

    thankForChoosing: 'Thank you for choosing to support\n{family_name}',
    
    theFamilyMappedOutTasks: 'The family has mapped out tasks that can support it. All that is left for you to do is to get in and select tasks',
    //'The family has mapped out tasks that can support them; all that\'s left for you is to log in and choose tasks.',

    everySmallTask: 'Every small task is a big relief.',
    letsStart: 'Let\'s get started',
    
    weEstablishedEnolaTo: 'We established Enola to help families during challenging times to efficiently activate the support network around them.',

    thankYouForWantedToSupport: 'Thank you for wanting to support the {family_name} family',
    wasSentToFamilly: '{full_name}, your request to join the support circle has been sent to the {family_name} family. Due to the sensitivity of this time, it may take some time.',
    //
    continue: 'Continue',
    supportForTheFamily: 'Support for the {family_name} family',
    yourRequestSentToTheFamily: 'Your request to join the support circle has been sent to the family. Thank you very much for your concern :)',
    //
    inspiredBy: 'Inspired by',
    youShouldRegister: 'To preserve the privacy of the family, you should register to this system.',
    afterRegistration: 'After registration you can take a task after approvement from the family.',
    someTasksFrom: 'Some tasks from ',

    regConditions_0: 'Just before we start, due to the sensitivity of our activity, please take a moment to read and approve our ',
    regConditions_url_0: 'Terms of Use',
    regConditions_1: ' and ',
    regConditions_url_1: 'Privacy Policy',
    regConditions_2: ' which are designed to protect the family and maximize support for them. Clicking the button confirms that you have read and agreed.',

    iCannotTake: 'I cannot take this task',
    scheduledFor: 'Scheduled for: ',
    taskToCalendar: 'Adding the task to your calendar is a good way to keep track of it.',
    iWillTake: "I'll take the task",

    sendMessage: 'Send message',
    sendWhatsApp: 'Send WhatsApp message',
    forMoreDetails: 'For more details:',
    tasks: 'tasks',

    tab_all: 'All', 
    tab_available: 'Available',
    tab_my: 'My',
    finished: 'Ended',

    profile_takenTasks: 'Taken tasks',
    profile_CompletedTasks: 'Completed tasks',
    profile_logout: 'Log out on this device',
    profile_allMy: 'All my tasks',

    iWillDo: "I'll do it",
    description: 'Description',

    calendarItalic: 'To ensure you remember, please add this task to your calendar',

    tasks_Taken: 'Taken',

    any_time: 'Any time',

    contactForLink: 'Contact family owner and recieve sign up link',
    signUpFillTheForm: 'Sign up (fill the form on sign up link click, you can view some tasks from family before filling the form)',
    clickOnLoinLink: 'Click on login link (will be sent after sign up confirmation) in SMS message (if app is installed, you will be logged in the app)',
    ifTryDemo: 'If you want to try app without registration, you can use demo account (just click on button below)',
    yHaveTo: 'You have to:',

} as const;
