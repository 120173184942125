import './ButtonSecondary.css';

export default function ButtonSecondary({
    txt = "Example",
    onClick = () => null,
    width = ''
}) {
    return <button
        style={{ width: width }}
        onClick={onClick} className='AppButtonSecondary'>{txt}</button>
}