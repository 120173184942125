import './ButtonPrimary.css';

export default function ButtonPrimary({
    txt = "Example",
    onClick = () => null,
    width = ''
}) {
    return <button
    style={{width: width}}
     onClick={onClick} className='AppButtonPrimary'>{txt}</button>
}