import phone_img from './contact_images/phone.svg';
import wa_img from './contact_images/whats_app.svg';
import msg_img from './contact_images/message.svg';
import calendar_img from './contact_images/to_calendar.svg';

export const ContactImage = {
    Phone: phone_img,
    WhatsApp: wa_img,
    SMS: msg_img,
    Calendar: calendar_img
}
