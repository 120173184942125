import './WelcomeTo.css';
import logo from '../images/logo_thumb.png';
import ButtonPrimary from '../basePageComponents/buttons/ButtonPrimary';
import book from '../images/welcome_images/book.png';
import pen from '../images/welcome_images/pen.png';
import pack from '../images/welcome_images/pack.png';
import './WelcomeDetails.css';

export default function WelcomeTo({familyName = "The Cohen", onClick = ()=> null}) {
    return <div className='base_background WelcomeTo_root'>
        <img className='WelcomeLogo' src={logo} />
        <div style={{ height: 20 }} />
        <div className="welcomeTiitle _black_32">Welcome to ENOLA</div>
        <div className="welcomeDescription _black">
            {familyName} has invited you to help during a difficult time.
            Choose tasks to support them and build a caring community
        </div>
        <div className="welcomeImages" style={{ position: 'relative' }}>
            <div className="welcomeImageDiv" style={{ marginLeft: 50, zIndex: 10 }}>

                <div className='img_WelcomeDiv'>
                    <img src={book} className='img_Welcome' alt='i' />
                </div>

                <div className='img_WelcomeDiv_2'>
                    <div style={{ backgroundColor: '#16FAD5', width: '20%' }} className="welcomeImageDivLine"></div>
                    <div style={{ backgroundColor: '#C3C8CC' }} className="welcomeImageDivLine"></div>
                </div>
            </div>


            <div className="welcomeImageDiv"
                style={{ position: 'relative', marginTop: -30, zIndex: 20 }}
            >
                <div className='img_WelcomeDiv'>
                    <img src={pen} className='img_Welcome' alt='i' />
                </div>

                <div className='img_WelcomeDiv_2'>
                    <div style={{ backgroundColor: '#FA59B0', width: '20%' }} className="welcomeImageDivLine"></div>
                    <div style={{ backgroundColor: '#C3C8CC' }} className="welcomeImageDivLine"></div>
                </div>
            </div>

            <div className="welcomeImageDiv"
                style={{ marginLeft: 50, position: 'relative', marginTop: -30, zIndex: 10 }}>

                <div className='img_WelcomeDiv'>
                    <img src={pack} className='img_Welcome' alt='i' />
                </div>

                <div className='img_WelcomeDiv_2'>
                    <div style={{ backgroundColor: '#FAB90D', width: '20%' }} className="welcomeImageDivLine"></div>
                    <div style={{ backgroundColor: '#C3C8CC' }} className="welcomeImageDivLine"></div>

                </div>
            </div>
        </div>   
        <div style={{ height: 114 }} />
        <div className='WelcomeFooter'>
            <ButtonPrimary onClick={onClick} width='calc(100% - 15px)' txt="Let's go" />
        </div>
        <div style={{ height: 38 }} />
    </div>
}