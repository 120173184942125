import { TranslationContextProvider } from 'schummar-translate/react';
import ButtonPrimary from '../basePageComponents/buttons/ButtonPrimary';
import './WelcomeDetails.css';
import t from '../translations/translate.ts';
import TextField from '../fields/TextField.js';
import TextFieldType from '../enums/TextFieldTypes.js';
import { useState } from 'react';
import { signUpRequest } from './SignUpForm.js';
import { NotificationContainer } from 'react-notifications';

export default function WelcomeDetails(
    { familyInviteToken, familyInfo,
        setFirstName, firstName, setLastName, lastName, setPhoneNumber, phoneNumber,
        letsGo
    }
) {
    const [phoneCode, setPhoneCode] = useState("+972");
    //
    if (firstName.length >= 80) {
        setFirstName(firstName.substr(0, 79));
        NotificationManager.warning("Text input", "First name length should be less than 80 symbols");
    }
    if (lastName.length >= 80) {
        setLastName(lastName.substr(0, 79));
        NotificationManager.warning("Text input", "Last name length should be less than 80 symbols");
    }
    //
    return <TranslationContextProvider locale={localStorage.getItem("locale")}>
        <div className="base_background WelcomeDetails_root">
            <div className="WelcomeDetails_title _black_28">Details</div>
            <div className="WelcomeDetails_form">
                <div className='_grey_16' style={{ marginBottom: 8 }}>Name</div>
                <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} type={TextFieldType.TEXT} />
                <div style={{ height: 24 }} />
                <div className='_grey_16' style={{ marginBottom: 8 }}>Phone number</div>
                <TextField
                    codeValue={phoneCode}
                    onCodeChange={(e) => { setPhoneCode(e.target.value) }}
                    value={phoneNumber}
                    onChange={(e) => { setPhoneNumber(e.target.value) }}
                    type={TextFieldType.PHONE} />
                <br />
                Please read and agree to our
                &nbsp;<a target='_blank' href={'/docs/' + localStorage.getItem('locale') + '/TERMS.html'}>{t('regConditions_url_0')}</a>
                &nbsp;and&nbsp;
                <a target='_blank' href={'/docs/' + localStorage.getItem('locale') + '/PRIVACY.html'}>{t('regConditions_url_1')}</a>&nbsp;
                to protect the family and ensure maximum support.
                <br />
                Clicking the button confirms your agreement.
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br />
        </div>
        <div className="WelcomeFooter base_background">
            <ButtonPrimary onClick={() => {
                console.log("sign up requeest will be sent")
                signUpRequest(
                familyInviteToken, familyInfo,
                setFirstName, firstName, setLastName, lastName, setPhoneNumber, phoneNumber,
                letsGo, phoneCode);
            }
         } width='calc(100% - 15px)' txt="Let's get started on the tasks!" />
        </div>
        <NotificationContainer/>
    </TranslationContextProvider>;
}