import emotional_img from './category_images/Emotional_thumb.jpg';
import home_img from './category_images/Home_thumb.jpg';
import childcare_img from './category_images/Childcare_thumb.jpg';
import legal_img from './category_images/LegalRights_thumb.jpg';
import general_img from './category_images/General_thumb.jpg';
import medical_img from './category_images/Medical_thumb.jpg'

import emotional_icon from './category_label_icons/Emotional.svg';
import home_icon from './category_label_icons/Home.svg';
import childcare_icon from './category_label_icons/Childcare.svg';
import legal_icon from './category_label_icons/LegalRights.svg';
import general_icon from './category_label_icons/General.svg';
import medical_icon from './category_label_icons/Medical.svg';

export const CategoryImages = {
    emotional: emotional_img,
    home: home_img,
    childcare: childcare_img,
    legal: legal_img,
    general: general_img,
    medical: medical_img
};

export const CategoryLabelIcons = {
    emotional: emotional_icon,
    home: home_icon,
    childcare: childcare_icon,
    legal: legal_icon,
    general: general_icon,
    medical: medical_icon
};
